/** Checks if the value is an object */
export const isObject = (value) =>
    typeof value === 'object' && value !== null;

/**  Converts camelCase to snake_case */
export const camelToSnake = (string) => {
    if (!string) return '';
    return string
        .replace(/(^[A-Z])/, ([first]) => (first || '').toLowerCase())
        .replace(/([A-Z])/g, ([letter]) => `_${(letter || '').toLowerCase()}`);
};

/**  Converts camelCase object to snake_case object */
export const camelToSnakeObject = (object) => {
    if (isObject(object) && !Array.isArray(object)) {
        const n = {};
        Object.keys(object).forEach((k) => {
            const key = camelToSnake(k);
            if (!key) return;
            return (n[key] = camelToSnakeObject(object[k]));
        });
        return n;
    }

    if (Array.isArray(object)) {
        const n = [];
        object.forEach((k) => n.push(camelToSnakeObject(k)));
        return n;
    }
    return object;
};
