import { scrollIntoView } from 'seamless-scroll-polyfill';
/**  Converts camelCase to snake_case */
export const camelToSnake = (string) => {
    if (!string)
        return '';
    return string
        .replace(/(^[A-Z])/, ([first]) => (first || '').toLowerCase())
        .replace(/([A-Z])/g, ([letter]) => `_${(letter || '').toLowerCase()}`);
};
/** Checks if the value is an object */
export const isObject = (value) => typeof value === 'object' && value !== null;
/**  Scrolls to element with a specified id */
export const scrollTo = (id) => {
    const elementById = document.getElementById(id);
    if (elementById) {
        const behavior = 'smooth';
        isSafari()
            ? scrollIntoView(elementById, { behavior })
            : elementById.scrollIntoView({ behavior });
    }
};
/**  Checks if its browser or not */
const isBrowser = () => ![typeof window, typeof document].includes('undefined');
/**  Converts snake_case to camelCase */
export const snakeToCamel = (string) => {
    if (!string)
        return '';
    return string.replace(/([_][a-z0-9])/g, (group) => group.toUpperCase().replace('_', ''));
};
export const isSafari = () => false; //TODO
isBrowser && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
/** Wrapper for http calls  */
export const httpWrapper = (promise) => {
    return Promise.resolve(promise).then((value) => (value.data ? [value.data, null] : [{}, value.error]));
};
/**  Converts snake_case object to camelCase object */
export const snakeToCamelObject = (object) => {
    if (isObject(object) && !Array.isArray(object)) {
        const n = {};
        Object.keys(object).forEach((k) => {
            n[snakeToCamel(k)] = snakeToCamelObject(object[k]);
        });
        return n;
    }
    if (Array.isArray(object)) {
        const n = [];
        object.forEach((k) => n.push(snakeToCamelObject(k)));
        return n;
    }
    return object;
};
/**  Converts camelCase object to snake_case object */
export const camelToSnakeObject = (object) => {
    if (isObject(object) && !Array.isArray(object)) {
        const n = {};
        Object.keys(object).forEach((k) => {
            const key = camelToSnake(k);
            if (!key)
                return;
            return (n[key] = camelToSnakeObject(object[k]));
        });
        return n;
    }
    if (Array.isArray(object)) {
        const n = [];
        object.forEach((k) => n.push(camelToSnakeObject(k)));
        return n;
    }
    return object;
};
/**  Getting current screen value */
export const getCurrentScreen = () => {
    if (!window)
        return '';
    const screenWidth = (window === null || window === void 0 ? void 0 : window.outerWidth) || (window === null || window === void 0 ? void 0 : window.innerWidth);
    if (screenWidth > 1024) {
        return 'desktop';
    }
    else if (screenWidth > 767) {
        return 'tablet';
    }
    return 'mobile';
};
export const getCookieDomain = () => {
    if (window.location.host.includes('localhost')) {
        return 'localhost';
    }
    const parts = window.location.host.split('.');
    if (parts.length !== 2) {
        // no prefix like www or hi
        parts.shift();
    }
    return parts.join('.');
};
