export const UtmCookieKeys = {
    UTM_SOURCE: 'utm_source',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_MEDIUM: 'utm_medium',
    UTM_TERM: 'utm_term',
    UTM_CONTENT: 'utm_content',
    REFERRER: 'referrer',
    REFERRING_DOMAIN: 'referring_domain',
    GCLID: 'gclid',
    FBCLID: 'fbclid',
}
