export const getCurrentScreen = () => {
    if (!window) return '';

    const screenWidth = window?.outerWidth || window?.innerWidth;
    if (screenWidth > 1024) {
        return 'desktop';
    } else if (screenWidth > 767) {
        return 'tablet';
    }
    return 'mobile';
};