import * as amplitude from '@amplitude/analytics-browser';
import Cookies from 'js-cookie'

import {UtmCookieKeys} from "./enums/utmCookieKeys";
import {getCurrentScreen} from "./helpers/screenSizeHelper";
import {getCookie, setCookie} from "./helpers/cookiesHelper";
import {camelToSnakeObject} from "./helpers/objectsHelper";
import {getCookieDomain} from "./vue/utils/helpers";

const BASE_API_URL = amplitude_js.base_url || undefined
const AMPLITUDE_API_KEY = amplitude_js.api_key || undefined
const AMPLITUDE_ANALYTICS_URL = amplitude_js.analytics_url || undefined
const AMPLITUDE_DOMAIN = amplitude_js.domain || undefined
const AMPLITUDE_RESELLER_ID = amplitude_js.reseller_id || undefined


async function getDeviceId() {
    const locallyStoredHostingerDeviceId = getCookie('hostingerDeviceIdLocal');

    if (!locallyStoredHostingerDeviceId) {
        try {
            const {data: {hostingerDeviceId}} = await fetch('https://www.hostinger.com/api-proxy/api/set-hostinger-device-id', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer www.hostinger.com',
                },
                credentials: 'include',
            }).then(res => res.json())

            Cookies.set('hostingerDeviceIdLocal', String(hostingerDeviceId), {
                expires: 30,
                path: '/',
                domain: getCookieDomain()
            })

            return hostingerDeviceId;
        } catch {
            console.error('Error while generating device id.')
        }
    }

    return `${locallyStoredHostingerDeviceId}`;
}


const initAmplitude = async () => {
    if (!AMPLITUDE_API_KEY
        || !AMPLITUDE_DOMAIN
        || !AMPLITUDE_ANALYTICS_URL
        || !AMPLITUDE_RESELLER_ID) {
        return
    }

    const deviceId = await getDeviceId();

    amplitude.init(AMPLITUDE_API_KEY, {
        serverUrl: AMPLITUDE_ANALYTICS_URL,
        deviceId,
        defaultTracking: {
            attribution: false,
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false,
        },
        cookieOptions: {
            domain: AMPLITUDE_DOMAIN,
        },
        transport: 'beacon',
        flushIntervalMillis: 0,
        flushQueueSize: 1,
    });

    const identifyEvent = new amplitude.Identify();

    identifyEvent.setOnce('device_id', deviceId);

    Object.values(UtmCookieKeys).forEach((key) => {
        const utmCookie = getCookie(key);

        if (utmCookie) {
            identifyEvent.setOnce(key, utmCookie);
        }
    });

    amplitude.identify(identifyEvent);

    findAndSaveUtms()
}

const findAndSaveUtms = () => {
    const query = new URLSearchParams(document.location.search);

    Object.values(UtmCookieKeys).forEach((item => {
        const value = query.get(String(item))
        if (value && value.length > 0) setCookie(String(item), String(value))
    }))
}

const sendAmplitudeEvent = async (params) => {
    const referrers = filterEmptyObjectValues({
        current_document_referrer: document.referrer,
        original_document_referrer: getOriginalReferrer(),
    });

    amplitude.track(params.name, camelToSnakeObject({
        userGaId: getCookie('_ga')?.toString() || undefined,
        resellerId: AMPLITUDE_RESELLER_ID,
        brand: AMPLITUDE_DOMAIN.replace('www.', ''),
        ...Object.values(UtmCookieKeys).reduce((acc, key) => {
            if (getCookie(key).length > 0) {
                return ({
                    ...acc,
                    [key]: getCookie(key),
                })
            } else {
                return ({...acc})
            }
        }, {}),
        creationLocation: window.location.pathname.replaceAll('/', '_'),
        creationPath: window.location.pathname.replace(/\/$/, ""),
        deviceType: getCurrentScreen(),
        full_url: window.location.href,
        ...referrers,
        ...(params.properties || {}),
    }));
}

const getOriginalReferrer = () => {
    const originalReferrerCookie = getCookie('originalReferrer')
    if (!originalReferrerCookie) {
        setCookie('originalReferrer', String(document.referrer))
    }

    return getCookie('originalReferrer')
}

const filterEmptyObjectValues = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value != null && value !== "")
    )
}

await initAmplitude().then(() => {
    sendAmplitudeEvent({
        name: 'website.enter',
    });
})

export const closeChatbotAmplitudeEvent = () => {
    sendAmplitudeEvent({
        name: 'chatbot.close',
    });
}

export const showCookieConsentAmplitudeEvent = () => {
    sendAmplitudeEvent({
        name: 'website.consent.shown',
    });
}

export const clickCookieConsentAmplitudeEvent = (clickElement, cookiesSet) => {
    let clickElementValue;

    if (cookiesSet) {
        clickElementValue = clickElement + ':' + cookiesSet.join(',')
    } else {
        clickElementValue = clickElement
    }

    sendAmplitudeEvent({
        name: 'website.consent.clicked',
        properties: {
            click_element: clickElementValue
        }
    });
}
